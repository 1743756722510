import './assets/element-variables.scss'
import './assets/main.scss'

import { createSSRApp } from 'vue'
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus'

import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";

export function createApp() {
  const app = createSSRApp(App)
  const pinia = createPinia()
  app.use(pinia)
  app.use(router)
  app.use(ElementPlus)
  app.use(VueGtag, {
    config: {
      id: "G-5LG2KK7EBX"
    }
  }, router)
  return { app }
}
