import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'


// Define your routes
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  }
]

// Determine if we're in a server environment
const isServer = typeof window === 'undefined'

// Use createMemoryHistory for SSR and createWebHistory for client-side
const history = isServer ? createMemoryHistory() : createWebHistory()

const router = createRouter({
  history,
  routes
})

export default router
