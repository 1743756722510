<script setup lang="ts">

</script>

<template>
  <div class="footer">
    <div class="footer__bottom">
      <span class="footer__bottom-copyright">
        &copy; 2024 QuayCode
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .footer__bottom {
    width: 100%;
    text-align: center;

    .footer__bottom-copyright {
      white-space: nowrap;
    }
  }
}
</style>
