<script setup lang="ts">

</script>

<template>
  <div class="header">
    <div class="header__title">
      <h1>QuayCode</h1>
    </div>
    <div class="header__details">
      <a href="mailto:kontakt@quaycode.pl">kontakt@quaycode.pl</a>
      <a href="tel:+48455400659">+48 455 400 659</a>
    </div>

  </div>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  gap: 1rem;

  h1 {
    font-size: 3rem;
    margin: 0;
  }

  h3, h4, h5 {
    text-align: center;
    margin: 0;
  }

  .header__details {
    display: flex;
    gap: 2rem;

    a {
      font-weight: bold;
      text-decoration: none;
    }
  }

  @media (max-width: 768px), (max-width: 400px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 2rem;
    }
  }

  @media (max-width: 400px) {

    .header__title,
    .header__details {
      flex-direction: column;
      gap: 12px;
      text-align: center;
    }
  }
}
</style>